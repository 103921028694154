@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

.not_found_main {
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
}
.not_found_main .image_div span{
    font: normal normal normal normal 16px / 28px Poppins;

}
.image_div {
    gap: 35px;
    width: 560px;
    height: 562px;
    display: flex;
    opacity: 1;
    box-shadow: 0px 0px 30px #00000029;
    align-items: center;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
}
.image_div .img {
    width: 242px;
    height: 59px;
    opacity: 1;
}
.not_register {
    font: normal normal normal normal 16px / 28px Poppins;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.4px;
}
.request_account {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.request_account .text {
    color: #014754;
    font-size: 17px;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}
.back_btn_div {
    margin-top: 32px;
    margin-bottom: 8px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}
.jss10 {
    color: #e1ecf5;
    bottom: 0px;
    position: absolute;
    font-size: 17px;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
}
.back_btn_div .btn{
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    min-width: 64px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: none;
    user-select: none;
    transition: 150ms ease-in;
    height: max-content;
    box-shadow: none;
    padding: 0.375rem 0.75rem;
    color: rgb(17, 205, 239);
    background-color: transparent;
    font-size: 0.875rem;
    background-size: 150% !important;
    background-position-x: 25% !important;
}
.back_btn_div .back_btn {
    color: #ffffff !important;
    padding: 20px !important;
    font-size: 14px !important;
    font-family: Poppins !important;
    background-color: #014754 !important;
}