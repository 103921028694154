@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  height: 100%;
  overflow-y: hidden;
  letter-spacing: 0.5px !important;
  /* font-size: 13px !important; */
}


/* Style both the horizontal and vertical scrollbars globally */
*::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Style the track (background) of the scrollbar */
*::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light gray track */
  border-radius: 10px;
}

/* Style the draggable thumb (the scrollbar itself) */
*::-webkit-scrollbar-thumb {
  background-color: #e49a10 !important; /* Default color of the thumb */
  border-radius: 10px;
}

/* Hover effect for the thumb */
*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker gray when hovering */
}
.sidebar_show a[href="/all-category"]{
  display: none;
}
#chakra-modal-2 a[href="/all-category"]{
  display: none;
}
option {
  color: black;
}
.button-div { 
  float: right;
}
.button-div .add-btn{
  bottom: 7px;
}
.internal-btn-div{
  position: absolute;
  right: 34.3rem;
  top: 1.3rem;
  z-index: 1;
  background-color: #fff;
  margin-top: .5rem;
  border-radius: 30px;
  /* height: 6.5vh; */
  padding-left: 14px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

}
.invalid {
  border: 2px solid red !important;
  /* background-color: #fdd; */
}
.disabled-dropdown{
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  cursor: not-allowed;
}
.quotation-upload{
  cursor: pointer;
  padding-left: 3px !important;
}
.add-btn {
  /* background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important; */
  /* color: #fff !important; */
  /* padding: 25px !important; */
  /* margin: 21px !important; */
  /* margin-left: 10px !important; */
  margin-top: 21px;
  padding: 0 !important;
  background: none !important;
  border: none !important;
}

.add-project {

  margin-top: 21px;
  margin-left: 0rem;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  cursor: pointer;
  height: fit-content !important;
}
.add-project span{
  margin-left: .8rem;
}
.add-project-btn{
  background: rgb(95, 161, 95) !important;
  margin: 0 auto;
  
}
.add-project .add-project-icon{
  /* background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important; */
  color: #000d55;
  border-radius: 50%;
  /* cursor: pointer; */

}
.chakra-modal__content {
  max-width: 100% !important;
  width: 870px !important;
  /* height: 625px !important; */
}
.data-change-content{
  width: 1160px !important;
  max-height: 100% !important;
}
.data-change-content  
.add-form-modal-content{
  /* height: 100vh !important; */
}

.input-box  .form-label {
  font-size: 14px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  
}
.f-600{
  font-weight: 600;
}
.input-box  input {
  font-size: 13px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  width: 	240px ;
  padding: 4px 8px;
}
.input-box .custom--dropdown-container{
  width: 	240px ;
}
.input-box  textarea{
  font-size: 13px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  width: 675px;
}
.input-box .react-datepicker__input-container input{
    font-size: 13px !important;
    height: 1.9rem;
}
.input-box .custom--dropdown-container  {
  font-size: 13px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  
}
.input-box .custom--dropdown-container .dropdown-item {
  font-size: 13px !important;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  
}
.input-box{
  display: flex;
  align-items: center;
  justify-content: space-between;  
}
.input-box .input-group{
  width: 240px;
  
}
.input-box .input-group input {
  height: 1.85rem;
  
}
.input-box .input-group span {
  height: 1.85rem;
  display: flex;
  align-items: center;
}
.add-form-modal-content .addForm .input-box .amount-input{
  /* padding: 4px 8px; */
  

}

.export-select-date{
  font-size: 12px !important;
}
.page-box{
  margin-top: auto;
  padding-top: 0px;
  position: fixed;
  bottom: 0;
  /* right: 0; */
  width: 100% !important;
  background: #f9f9fb;
}
.page-box .flex-page-item{
  position: relative;
  right: 13rem;
}
.input-box .invoice-input{  
  width: 210px;
}
.form-card .row{
  padding: 0px;
}
.form-card h5{
  color: #000;
  font-weight: 600;
  margin-top: 8px ;
  font-size: 15px ;
}
.form-card h6 {
  /* margin-top: -25px; */
  margin-right: -25px;
  color: #0c374d;
  font-size: 14px;
  font-weight: 600;
}

.addForm .form-label {
  margin-bottom: 0 !important;
  color: #666869d6 !important;
  font-weight: 500 !important;
}
.addForm .row {
  margin-top: 12px;
}
.add-btn-row{
  margin-top: 0px !important;
}
.addForm .info-label {
  order: 1px solid gray !important;
  border-radius: 13px !important;
  padding: -4px !important;
  width: 123px !important;
  /* margin: 6px; */
  margin-left: 15px !important;
  background: linear-gradient(to right, #decba442, #3e515152) !important;
}

.modal-body .modalOPtion {
  width: 507px !important;
  /* height: 400px !important; */
}
.switch {
  position: relative;
  /* display: inline-block; */
  width: 60px;
  height: 29px;
  margin: 25px;
  margin-top: 0px;
  float: inline-end;
}
.expense-switch{
  margin-right: 0px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  /* padding: 2px; */
  margin: 2px;
}

input:checked + .slider {
  background: linear-gradient(to right, #4b79a1 0%, #283e51 51%, #4b79a1 100%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.card1 {
  position: relative;
  margin: 25px auto;
  /* width: 450px; */
  padding: 20px;
  /* box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.2); */
  border-radius: 5px 5px 5px 5px;
  border: 0;
  height: 100%;
  border: 1px solid #80808038;
}
.moadal-heading {
  border: 1px solid;
  /* top: 0; */
  border-radius: 10px;
  margin-top: -18px;
  bottom: 0;
  padding: 5px;
  background: linear-gradient(to right, #4b79a1 0%, #283e51 51%, #4b79a1 100%);
}
.moadal-heading span {
  color: #fff;
}
.card1 h5 {
  margin-top: -33px;
  color: #0c374d;
  font-size: 14px;
  font-weight: 600;
}

.card1 h6 {
  margin-top: -25px;
  margin-right: -25px;
  color: #0c374d;
  font-size: 14px;
  font-weight: 600;
}

.card2{
  padding-top: 10px !important;
}
.addForm .row .chakra-modal__footer {
  padding: 15px;
  padding-top: 15px;
}
/* inear-gradient( to right, #4b79a1 0%, #283e51 51%, #4b79a1 100% ) */

.notFound {
  margin: 160px;
}

.notFound img {
  width: 45%;
}
.modal-main {
  overflow: scroll;
}
.submit-btn {
  /* background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important; */
   background:#000d55 !important;
  margin-top: 10px;
  margin: 0 auto;
  border-radius: .375rem !important;
}
.chakra-modal__content-container {
  overflow: hidden !important;
}
.chakra-modal__content {
  height: 100vh !important ;
  overflow: scroll !important;
}
.chakra-modal__content::-webkit-scrollbar {
  display: none !important;
}
.input_with-edit {
  display: block;
  height: max-content;
  position: relative;
}
.edit-button {
  height: 15px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  /* top: 10px; */
}
.switch-btn {
  width: 40%;
}
.react-datepicker-wrapper {
  display: block !important;
}
.monthDate {
  border: 1px solid #80808045;
  height: 38px;
  width: 100%;
  border-radius: 5px;
  padding: 12px;
}

.date-filter-input{
  opacity: 0;
  height: 30px !important;
  width: 4vw;
  cursor: pointer;
  
}
.filter-date-pickur .react-datepicker-popper{
  width: 100px !important;
}
.filter-date-pickur .react-datepicker__navigation {
  top: 8px;
}
.credit-amount-tag{
  position: absolute;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  background: #2ea568;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  z-index: 1;
}
@keyframes blink {
  0% {
    background-color: rgba(26, 227, 241, 0.5);
  }
  50% {
    background-color: transparent; 
  }
  100% {
    background-color: #fff; 
  }
}

.month_blink {
  animation: blink 3s ease-in-out infinite; 
  border: 1px solid transparent; 
}
.month_blink.active {
  border-color: #ccc; 
}

@keyframes dateblink {
  0% {
    background-color: rgba(12, 241, 42, 0.5);
  }
  50% {
    background-color: transparent; 
  }
  100% {
    background-color: #fff; 
  }
}

.date_blink {
  animation: dateblink 3s ease-in-out infinite; 
  border: 1px solid transparent; 
}
.date_blink.active {
  border-color: #ccc; 
}

.date-right-element{
  border: 1px solid #dddfe9;
  width: 70px !important;
  height: 32px !important;
  border-radius: 8px;
}
.month-placeholder{
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.fixed-header {
  position: fixed;
  width: 870px;
  background: #f9f9fb;;
  /* opacity: 1; */
  z-index: 10000;
  height: 40px;
}
.fixed-header .expense-modal-header {
  padding: 0px;
  padding-left:12px;
  padding-top: 5px;
}
.add-form-modal-content .chakra-modal__body{
  padding: 12px !important;
}

.fixed-footer {
  width: 870px;
  background: #f9f9fb;;
  /* opacity: 1; */
  z-index: 1;
  height: 5vh;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;

}
.tdsEdit {
  margin-left: 323px;
}
/* .direct-Tax {
  height: auto !important;
} */
/* .chakra-modal__body {
  margin-top: 55px;
} */
.edit-button {
  cursor: pointer;
}
.switch-col {
  margin-top: -25px !important;
}
.genrateTdsPo .form-check-label {
  margin-left: 10px;
}
.flex-header {
  text-transform: none !important;
}
.span-star {
  color: red;
  /* margin: 8px; */
}
.submit-btn {
  margin-top: -12px;
}
.signin-auth {
  margin: 5vh auto 0vh !important;
  
}
.horizental-heading {
  display: flex;
  width: 28%;
}
.horizental-heading hr {
  width: 45%;
  color: green;
}
.list-btn{
  margin-top: 1.0rem;
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;  width: 90px;
  border-radius: 50% / 100%;

  padding: 2px;
  padding-left: 4px;
  padding-right: 3px;
  bottom: 5px;
  position: relative;
}

.list-btn .btn{
  background: none;
  padding: 0;
  border-radius: 50%;
  cursor:auto;
  border: none;
  outline: none;
  
}

.list-btn .btn:hover {
  background: none;
}
.list-btn-active{
  background-color: white !important;
}
.list-btn-disabled{
  background-color: #82868b;
  cursor: not-allowed;
  opacity: .7;
}
.horizental-row {
  border: 1px solid green;
  padding: 4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: space-between;
}
.payment-listing_box {
  /* border: 1px solid; */
  border-radius: 10px;
  padding: 0px 0px;
  margin: 10px 0 10px 0;
  transition: all 1.5s;
}
.payment-details{
  color: #000;
  font-weight: 600;
}
.payment-listing_box.hide {
  height: max-content;
}

.payment-listing_box.show {
  height: 100%;
}
.payment-inner_box {
  transition: all 0.5s;
}
.payment-inner_box.hide-content {
  opacity: 0;
  height: 0;
  visibility: hidden;
  
}
.payment-inner_box.hide-content .row,
.payment-inner_box.hide-content .col {
  display: none;
}
.payment-inner_box.show-content {
  opacity: 1;
  height: 100%;
  visibility: visible;
}
.payment-list_array {
  display: flex;
  
}
.amountSpan {
  border: 1px solid #3498ff;
  padding: 2px 16px;
  max-width: 400px;
  margin-left: 10px;
  font-weight: 500;
  height: 25px;
  background: #076ce0e8;
  border-radius: 8px;
  color: #fff;
}
.counting {
  width: 34px;
  height: 34px;
  border: 1px solid #1675e0a6;
  padding: 6px;
  border-radius: 18px;
  padding-left: 12px;
  margin-right: 15px;
}
.toggleImage-content {
  justify-content: end;
  flex: 1;
  margin-right: 12px;
  margin-bottom: 10px;
}
.payment-pdf-icon{
  justify-content: end;
  margin-right: 12px;
  flex: 1;
  margin-right: 12px;
  margin-bottom: 10px;
}
.toggleImage {
  /* padding: 10px; */
  margin-right: 15px;
}
.toggleImage img {
  width: 15px !important;
  height: 15px !important;
  transition: ease-in-out 0.2s;
}

.toggleImage img.open {
  transform: rotate(180deg);
}
.addForm {
  position: relative;
  margin-top: 35px;
  z-index: 1000;
}
.horizental-heading button {
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;
  border-radius: 10px !important;
}
.add-invoice-button {
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;
  border-radius: 10px !important;
}
.approve-button{
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;
  color: #fff !important;
  width: 80px !important;
}

.sweet_containerImportant {
  z-index: 99999;
}

.custom--dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: 100%;
}

.custom--dropdown-container .dropdown-input {
  padding: 4px 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 7px;
}

.custom--dropdown-container
  .dropdown-input
  .dropdown-selected-value.placeholder {
  color: #82868b;
}

.custom--dropdown-container .dropdown-tool svg {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom--dropdown-container .dropdown-tool svg.translate {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.custom--dropdown-container .dropdown-menu {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: 100%;
  padding: 5px;
  position: absolute;
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  overflow: auto;
  background-color: #fff;
  z-index: 99;
  max-height: 312px;
  min-height: 50px;
  display: block;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
}

.custom--dropdown-container .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom--dropdown-container .dropdown-menu.alignment--left {
  left: 0;
}

.custom--dropdown-container .dropdown-menu.alignment--right {
  right: 0;
}

.custom--dropdown-container .dropdown-item {
  padding: 7px 10px;
  cursor: pointer;
  -webkit-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
  border-radius: 6px;
  font-weight: 500;
}

.custom--dropdown-container .dropdown-item:hover {
  background-color: rgba(130, 134, 139, 0.05);
  color: #ff7300;
}

.custom--dropdown-container .dropdown-item.selected {
  background-color: rgba(255, 115, 0, 0.075);
  color: #ff7300;
  font-weight: 600;
}

.custom--dropdown-container .search-box {
  padding: 5px;
}

.custom--dropdown-container .search-box input {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.credit-amount-modal .uploade-file{
  height: 1.8rem;
  font-size: 11px !important;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.custom--dropdown-container .dropdown-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.custom--dropdown-container .dropdown-tag-item {
  background-color: #ff7300;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom--dropdown-container .dropdown-tag-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
}
.search-bar input{
  width: 300px;
  height: 32px;
  border-radius: 8px;
  transition: width .3s ease-out;
  border: 1px solid #dddfe9;
  background-color: #ededf7;
  color: gray;
}

.total_gst_amount{
  margin-right: 10px;
  text-align: left;
  /* border: 1px solid #f0f0f0; */
  border-radius: .5rem;
  padding: 2px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);


}
.custom-search-datepicker{
  height: 32px;
  border-radius: 8px !important;
  transition: width .3s ease-out;
  background-color: #fff;
  border: 1px solid #dddfe9 !important;

}
.form_error {
  padding: 2px;
  color: red;
}

.Toastify__toast-container,
.react-toast-notifications__container {
  z-index: 10000 !important;
}
.compare-body-fields {
  border-left: 1px solid black !important;
  margin-left: 406px !important;
}
.row-top {
  margin-left: -5px;
  margin-right: -5px;
}

.col1 {
  float: left;
  width: 50%;
  padding: 5px;
}
/* Clearfix (clear floats) */
.row-top::after {
  content: "";
  clear: both;
  display: table;
}
.row-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  /* padding: 27px; */
}
.rowC {
  padding-left: 20px;
  padding-bottom: 6px;
  font-size: 13 px;
  width: 100%;
}
.rowC .row-heading{ 
  font-size: 15px;
  color: black;
  font-weight: 600;
}
.dashboardSelect {
  width: 34% !important;
  padding: 8px !important;
  margin: 10px !important;
  background-color:  #ebeaf2!important;
  border: 1px solid #ebeaf2;

}
.topCard {
  width: 100% !important;
}
.rangeDatepicker {
  border: 1px solid black;
  border-radius: 13px;
  padding: 5px 20px;
}
.expense-modal-header {
  color: #212529;
  font-size: 1.3rem !important;
  font-weight: 400 !important;
}
.expense-modal-header  .header-pr{
  color: blue;
 
  font-weight: 200 !important;
}

.span-text{
  margin-left: 1.5rem;
    color: black;
}

.info-model .expense-modal-header{
  color: #434E5F;
  margin-top: .7rem;

    
}

.info-model .expense-modal-header span{ 
  color: #434E5F;
    margin-top: 2px;
    margin-left: 15px;
}

.heading-h5{
  color: #666869d6;
  font-size: 12px;
  font-weight: 500;
  margin-left: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
}
.info-divider{
  width: 100% !important;
  color: #E9ECEF;
  /* margin: 2% !important; */
}
.info-model .table-info  thead{
  background-color: #F7FAFC;
  border-radius: 8px;
  width: 96% !important;
  margin: 2% !important;
}
.info-model .table-info  thead tr th{
  text-transform: capitalize;
}
.info-model .table-info  tbody td{
  font-weight: 500;
  font-size: 14px;
  color: #3F4A54;
}
.info-model .addForm{
  margin-bottom: 10px;
}
  

  .info-model .addForm .info-row {
    margin-top: 5px !important;
    width: 98%;
    margin: .5%;
}
.info-model .addForm .form-label{
  font-size: 13px !important;
}
.info-model .addForm .form-control {
  font-weight: 600;
  background-color: #F5F7FA;
  border: none;
  height: 2rem;
  color: #3F4A54;
  cursor:auto;
  font-size: 14px;
}


.checkmark::after {
  content: "\2714"; /* Unicode character for checkmark */
  color: green;
  font-size: 20px;
}

.crossmark::after {
  content: "\274C"; /* Unicode character for cross mark */
  color: red;
  padding-inline-end: 4px;
  font-size: 10px;
}
.info-model .check-cross-mark{
  margin-top: 25px;
}
.info-model .info-table{
  width: auto;
  margin: 2%;
  border: 1px solid #EAEDF0;
  border-right: 0;
  border-bottom: 0;
}
.change-modal-table{
  overflow-x: auto ;
}
.info-model .info-table table thead tr th {
  border-bottom: 1px solid #EAEDF0;
  border-right: 1px solid #EAEDF0;
}
.info-model .info-table table tbody tr td {
  border-bottom: 1px solid #EAEDF0;
  border-right: 1px solid #EAEDF0;
  font-weight: 600;
  font-size: 13px;
}
.info-model .info-table table tfoot td,.info-model .info-table table tfoot th {
  border-bottom: 1px solid #EAEDF0;
  /* border-right: 1px solid #EAEDF0; */
  font-size: 13px;
  font-weight:700;
  color: #016531;
}
.info-model .info-table table tfoot  td.total-spent {
  /* border-bottom: 1px solid #EAEDF0; */
  border-right: 1px solid #EAEDF0;
  font-size: 14px;
  padding-left: 1.5rem;

}
.info-model .info-table table tbody tr .download-data svg{
  font-size: 18px !important;
}

.info-model .addForm .card {
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    border-top: 0;
    padding-bottom: 1rem;
}

.creditAmount{
  font-weight: 600;

}
.info-model .addForm .nav-tabs .nav-item button{
  color: #666869d6;
  font-weight: 500;
}
.sidebar-link .nav-item .nav-text{
  letter-spacing: .5px !important;
}
.info-model .history-checkmark{
  text-align: center;
}

.arrow_button {
  width: 40px;
  height: 40px;
  z-index: 1;
  background-color: #21263c;
  margin-top: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50% / 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

}

.arrow_button::after{
  font-size: 26px;
  font-weight: 600;

  color: #fff;
  
}
.arrow_button_right::after{
  content: '>';
  padding-left: 0px;
}
.arrow_button_left::after{
  content: '<';
  padding-left: 0px;

}
.graph_button {
 
  display: flex;
  position: absolute;
  top: .6rem;
  z-index: 1;
  /* padding: 0.385rem 0.769rem; */

  background-color: #f9f9fb ;
  margin-left: 1rem;
  height: 30px;
  border: 1px solid #ebeaf2;
  border-radius: 0.375rem;
  transition: all 0.5s ease-in-out;
  right: 23rem !important;
  padding: auto;
  height: 2rem;  
}

.export-popover{
  right: 80px;
  box-shadow: none !important;
  border: 1px solid #ebeaf2
}
.export-popover button{
  font-size: 12px;
}
.menu-text{
  cursor: pointer;
  font-size: 13px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  /* margin: 10px; */
  border-radius: 8px;
  
}
.menu-text:hover{
  background: blue;
  font-weight: 600;
  color: white;
}
.Menu-Bar{
  border-radius: 0.37rem;
}
.search-menu-item{
  cursor: pointer;
  font-size: 13px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #ebeaf2;
  font-weight: 600;
}

 .menu-button{
  border-right: 1px solid #ebeaf2;

}
.modal-header-search{
    background-color: #f9f9fb ;

}
.graph_button .graph_icon_button{
  cursor: pointer;
  font-size: 20px;
  color: #000000;
  padding: 0.385rem 0.769rem;

  border-right: 1px solid #ebeaf2;
  outline: none;
  border-radius: 0.375rem;

}
.modal-content-search{
  width: 600px !important;
  height: 400px !important;
}
.modal-content-legacy{
  height: 300px !important;
  width: 550px !important;
}

.modal-content-legacy .fixed-header {
  position: fixed;
  width: 550px;
  background: #f9f9fb;;
  /* opacity: 1; */
  z-index: 1;
  height: 55px;
}
.graph_button .graph_icon_button svg{
  font-weight: 800;
}
.fixed-header .chakra-modal__close-btn{
  font-size: 9px;
  height: 15px;
  width: 10px;
  color: red;
  box-shadow: none !important;
}
.graph_button .active_graph{
  background-color: #000d55;
  color: #fff;

}

.sidebar_show {
  width: 200px !important;
  
}
.sidebar_hide{
  width : 35px !important;
  color: white !important;
}
.add-credit-btn{
  position: absolute;
  /* width: 70px; */
  right: 30px;
  height: 30px;
  padding: 6px 4px;
  background-color: #22b378;
  color: #fff;
  border: 1px solid #f0f0f0;
  margin-top: 13px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-hide .arrow_button {
  width:60px;
}
.all_category {
  padding: 25px;

}
.gt-tag{
  color: black;
  font-size: 9px;
  font-weight: 600;
  border: 1px solid #a8a8a8;
 
}
.all_category .heading {
  display: block;
    /* overflow: hidden; */
    overflow-y: auto;
    width: 100%;
    height: 100%;
    /* background-color: #F9FAFC; */
    position: relative;
    font-size: 18px;
    /* margin: 10px 0; */
}
.category-main-card .category-card{
  display: inline-block;
    width: 145px;
    height: 165px;
    vertical-align: top;
    margin-right: 30px;
    background-color: transparent !important;
    -webkit-transition: height 0.2s ease-in-out;
    -moz-transition: height 0.2s ease-in-out;
    -o-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.04);

}

.category-span{
  display: block;
  padding: 20px 15px 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  min-height: 154px;
}
.add-vendor-gst{
  color: blue;
  background: none !important;
  
}

.multiple-gst-field .form-control{
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
}
.gst-fields-div{
  display: flex;
  flex-direction: row;
}
.multiple-gst-field .gst-remove-btn{
  color: rgb(235, 62, 62);
  margin-left: 10px;
  font-size: 12px;
}
.multiple-gst-field .custom--dropdown-container{
  width: 240px;
  height: 28px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 11px !important;

}
.category-span::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  margin: auto;
  visibility: hidden;
}
.category-main-card .category-card span .card-label{
  display: inline-block;
    height: 70px;
    width: 70px;
    box-shadow: 1px 3px 8px -3px #9E80F2;
    vertical-align: top;
    background: #ffffff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    max-width: 100%;
    margin-bottom: 5px;
}
.card-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(2);
  background-position: -992px 0;
  display: inline-block;
    margin: auto;
    height: 14px;
    width: 14px;
    color: #422AFB;
}
.card-heading {
  font-size: 16px;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 500;
    line-height: 17px;
    color: #000;
    margin-bottom: 0;
    min-height: 35px;
    max-height: 35px;
    margin-top: 14px;

}

.previous-text {
  font-weight: 600;
  color: #016531; 
  font-size: 0.8rem;
  margin-top: 3px;

}
.red {
  color: #800020 !important;
}
.green {
  color: #28A745 !important;
}
.blue {
  color: #4587ce !important;
  font-weight: 600;
}
.gray {
  color: #6c7184 !important;
  font-weight: 600;
}
.red {
  color: #DC3545 !important;
  font-weight: 600;
}
.addform2 {
  margin-top: 0px;
  border: 1px solid #EAEDF0;
  border-top: 0;
  /* padding: 10px; */
}
#justify-tab-example3 .nav-item {
  padding-bottom: 0;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: 800;

}
#justify-tab-example3 .nav-item button{
  border-top: none;
} 
#justify-tab-example1 .nav-item{
  font-size: 16px;
  font-weight: 800;
} 
#justify-tab-example1 .nav-item .active ,#justify-tab-example3 .nav-item .active{
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;
  color: #fff;
} 

.button-not-visible{
  visibility: hidden;
}
.credit-popover{
  float: right;
}
.disabled-select{
  background-color: #e9ecef;
  cursor: not-allowed;
}
.text_uppercase {
  text-transform: uppercase !important;
}

.data_compare_red {
  background-color: tomato;
  font-weight: 600;
}
.data_compare_green {
  background-color:#60bd8d;
  font-weight: 600;
}
.test_css {
  background-color: #000;
}
.add-amount-btn{
  position: relative;
  color: black;
  font-weight: 600 !important;
  /* height: 20px; */
  /* padding-right: 3px; */
}
.amount-popover{
  position:  relative;
  /* right: 100px !important; */
 bottom: 38px;
 right: 0;
 max-width: fit-content !important;
 box-shadow: none !important;
 border-radius: 0.375rem !important;
}
.amount-popover .chakra-popover__body{
  padding: 0 !important;
}
.add-amoun-button{
  width: 20px;
  border-right: 1px solid #ccc;

}
.amount-popover .add-amount-input{
  box-shadow: none !important;
}
.amount-popover .check-btn{
  /* margin-left: 10px; */
  color: #1fe07c;
}
.amount-popover .input-group {
  width: 210px;
}
.main-table-heading{
  position: absolute;
  margin-left: 23px;
  font-size: 16px;
  font-weight: 600;
  top: .10rem;
}

.table-heading{
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;

}
.row-col{
  width: 50% !important;
}
.data-change-modal-header{
  margin-top: 0px !important;
  padding: 0 !important;
  padding-bottom: 0px !important;
}
  .data-change-content   .chakra-modal__body{
    padding: 0  ;
    padding-left: 5px;
    overflow-x: hidden  ;
  }
  .data-change-body{
    padding: 0;
    overflow-x: hidden;
  }

 .data-change-modal-header .modal-header-row{
  margin: 0px;
  border: 1px solid #ebeaf2;
  width: 100%;
  font-size: 16px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #f9f9fb;
  justify-content: center;

}
.data-change-modal-header .modal-header-row h6{
  /* padding-left: 30%; */
  text-align: center;

}
.embrill-table{
  border-bottom: 1px solid #ebeaf2;
  margin-bottom: 25px;
  
}
.embrill-table thead{
  vertical-align: bottom;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  
}
.vendor-ledger-icon {
  display: flex;
  margin-top: 2px;
  justify-content: space-between;
}
.vendor-ledger-icon .ledger-icon{
  margin-left: 15px;
  border: 1px solid #ddd;
  height: 25px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 0.375rem;
  color: #000;
}
.ledger-year-select{
  border: 1px solid #ddd !important;
  height: 30px !important;
  width: 150px !important;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
}
.ledger-year-select .date-icon{
  font-size: 18px;
  margin-left: 8px;
}
.embrill-table thead tr{
  border-color: inherit;
  border-style: solid;
  /* border-width: 0; */
  
  height: 100%;
}
.ledger-details .details-container {
  position: relative;
  box-shadow: 0px 0px 6px #ccc;
  width: 90%;
  height: auto;
  margin-bottom: 35px;
  margin-left: 4rem;
}

.ledger-details .ledger-template{
    font-size: 12px;
    color: #333333;
    background: #ffffff;
    padding:  0.400000in  0.550000in;
}
.ledger-details .ledger-main{
  margin-top: 40px;
}
.ledger-details .ledger-table .heading th{
  border-top: 1px solid #000;  
  border-bottom: 1px solid #000 !important;  
  padding: 5px 5px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  text-align: start;
  font-size: 12px;
  color: #000;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;

}
.ledger-details .ledger-table .border-top {
  border: 0;
  border-top: 1px solid #000 !important;
  padding-top: 10px;
}
.ledger-details .ledger-table .border-bottom {
  border-bottom: 1px solid #000 !important;
  padding-bottom: 13px;
  padding-top: 5px;

}
.bold-text{
  font-weight: 600;
}
.common-tooltip{
  color: #fff !important;
  background-color: #3d3e4d !important;
  margin-top: 3px !important;
  border-radius: .375rem !important;
  font-weight: 500 !important;

}
.header-left{
  width: 400px !important;
}
.ledger-logo {
  max-width: 130px;
  /* background-color: #000000; */
  margin-left: 30px;
  margin-bottom: 5px;
  border-radius: 3px;

}
.ledger-logo img{
  height: 100px;
}

.ledger-pdf-logo {
  max-width: 130px;
  /* background-color: #000000; */
  margin-left: 60px;
  border-radius: 3px;
  /* margin-top: 20px; */
  z-index: 1000;
}
.ledger-pdf-logo img{
  height: 100px;
}
.header-right{
  width: 280px;
}
.ledger-details .ledger-table tbody td{
 
  padding: 5px 5px;
  text-align: start;
  font-size: 12px;
  align-items: start;
  line-height: .9rem;
  border-bottom: 0 !important;
}
.top-fixed,.amount {
  display: flex;
}
.ledger-details .ledger-main .ledger-date{
  text-align: end;
  margin-bottom: 15px;
}

.ledger-details .ledger-header .header-box .title{
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase !important;
}





.ledger-pdf-header .header-box{
  width: 350px;
}
.ledger-pdf-table table thead tr th{
  padding-bottom: 14px !important;
  padding-top: 1px !important;

}

.ledger-pdf-table table thead tr th,.ledger-pdf-table table tbody tr td {
  font-size: 18px !important;
  font-weight: 900 !important;
}
.ledger-pdf-header .header-box .title{
  font-size: 20px !important;
  font-weight:900 !important;
  text-transform: uppercase !important;

}
.ledger-header .header-box p {
  margin-top: 0px;
}
.ledger-pdf-header .header-box .address{
  font-size: 20px !important;
  margin-top: 0px !important;

}
.ledger-pdf-container .ledger-date{
  font-size: 18px !important;

}
.vendor-statement{
  text-align: center;
  margin: 35px;
}
.pdf-main-container{
  position: absolute !important;
  /* z-index: 1000 !important;
  top: 22rem; */
  width: 75%; 
  z-index: -1;
}
.payment-mode-pdf{
  position: absolute;
  top: 0;
}
.embrill-table thead tr th{
  border-top:1px solid #ebeaf2;
  background-color: #f9f9fb;
  color: #000;
  font-size: 11px;
  padding-left: 15px;
  font-weight: 500;
  padding-right: 15px;
  padding: 12px 8px !important;

}


.embrill-table .embrill-table-data {
  padding: 12px 8px !important;
  border-bottom: 1px ;
  padding-left: 15px;
  padding-right: 15px;
  border-color: inherit;
  border-style: solid;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 9999px transparent;
  color: #000 !important;
  font-weight: 500;
  font-size: 13px;

  /* border-width: 0; */

  height: 100%;
}

.vendor-expense-table thead tr th{
border-top: none;
color: #6C7184;
font-size: 11px;
font-weight: 600;
}
.logout-btn {
  font-weight: 600;
  background: linear-gradient(90deg, #ff7e5f, #feb47b); /* Change colors as needed */
  color: white; /* Adjust text color for contrast */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Adjust padding for button size */
  border-radius: 5px; /* Optional: rounded corners */
  height: 4.5vh;
  border-radius: 10px;
  cursor: pointer; /* Change cursor on hover */
  transition: background 0.3s ease; /* Smooth background transition */
}
.vendor-edit-btn{
  background:none;
  position: relative;
  font-size: 16px;
  margin-left: 70px;
  color: gray;
  font-weight: 600;
}
.logout-btn:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reverse gradient on hover */
}
.gst-field-no{
  margin-top: 12px;
}
.role_add_btn {
  align-items: center;
  justify-content: center;
}

.user-role {
  -webkit-box-shadow: 0 3px 5px -3px #000;
  -moz-box-shadow: 0 3px 5px -3px #000;
  box-shadow: 0 3px 5px -3px #000;
    margin-top: 10px;
}
.user-role-table Thead Tr {
  background: linear-gradient(
    to right,
    #4b79a1 0%,
    #283e51 51%,
    #4b79a1 100%
  ) !important;
  color: white;
 
}
.grid-amount .topCard{
  width: fit-content;
  border-radius: 10px;  
 
  box-sizing: border-box;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ebeaf2;
  background-color: #fff;
  box-shadow: 0 3px 5px -3px #dbdbdb;

}
.grid-amount .topCard .chakra-stat dl dt{ 
  color: #6c7184 !important;
}
.grid-amount .topCard svg{
  color: #22b378;
}
.grid-amount .topCard .chakra-stat dl dd{ 
  color: #000000 !important;
  font-weight: 500;
}
.weekly-expense-graph{
  border: 1px solid #ebeaf2;
  background-color: #fff;
}
.address-link{
  color: #408dfb !important;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.vendor-add-checkbox{
  margin-left: 5px !important;
  margin-right: 33px;
}
.contact-box .salu-select{
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-color: #fff;
  outline: none;
  width: 60px;
}
.contact-box .input-contact{
  width: 160px !important;
  margin-left: 20px;
}
.vendor-dropdown-menu{
  width: 300px !important;
}
.vendor-dropdown-item {
  
}
.vendor-dropdown-item .label{
  font-size: 12px;
  color: #000;
}
.vendor-dropdown-item .helper{
  font-size: 10px;
  color: gray;
  white-space: normal;
  overflow-wrap: break-word;
}
.vendor-selected{
  background: #408dfb !important;
}
.selected-color {
  color: white !important;
}
.vendor-add-select{
  height: 30px;
    width: 239px;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    background-color: #fff;
    outline: none !important;
    font-size: 12px;
}
.country-code{
  font-size: 12px !important;
}

.vendor-add-testarea{
  width: 240px !important;
}
.weekly-graph-flex{
  background-color: #f9f9fb;
  font-size: 18px;
  border-radius: 10px 10px 0 0 !important;
  padding-left: .75rem;
  height: 50px;
  padding-right: .75rem;
  border-bottom: 1px solid #ebeaf2;
  box-sizing: border-box;
}
.rupee-symbol{
  font-family:'Times New Roman', Times, serif !important;
}
.user-role-table Thead Tr Th,.user-role-table Tbody Tr Td{
  font-weight: 800;
  font-size: 14px;
}

.tab-btn{
  height: 30px;
  margin-top: 14px;
}
.tab-btn .tab-btn-div{
  border: 1px solid #ebeaf2;
  background-color: #f9f9fb ;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.tab-btn .tab-btn-div .div-btn{
  border: 0;
  border-right: 1px solid #ebeaf2;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  border-radius: 5px;
  width: 5.5rem;

}
.menu-ellipsis-btn{
  margin-right: 1rem;
  padding: 0.385rem 0.769rem;
  background-color: #f9f9fb ;
  margin-left: 1rem;
  height: 30px;
  border: 1px solid #ebeaf2;
  border-radius: 0.375rem;


}

.add-new-btn .new-btn{
  color: #f0f0f0;
  background-color:#22b378;
  border-color: #22b378;
  padding: 0.385rem 0.769rem;
  height: 30px;
  border-radius: 0.375rem;
  font-size: .8rem;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}
.new-btn .new-plus-icon{
  margin-right: 5px;
}
.active-tab-btn{
  background-color: #000d55;
  color: white !important;
  font-weight: 600 !important;
}
.page-icon{
  background-color: #fff;
  border: 1px solid #ebeaf2;
  border-radius: 0.375rem;

}
.select-page{
  background-color: #f0f0f0;
  border: 1px solid #ebeaf2;
  border-radius: 0.375rem;
  font-size: 14px;
  

}

.select-page .chakra-select{
  font-size: 14px;
}
.table-top-bar .table-summary{
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #6c7184;;
}
.table-top-bar-item{
  border-right: 1px solid #ebeaf2;
  margin-right: 20px;

}
.table-top-bar-item .top-bar-icon{
  border: 1px solid #ebeaf2;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: gray;

}
.table-top-bar-item .top-bar-icon svg{
  font-size: 25px;
}
.table-top-bar-item .top-bar-text{
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #6e6e6e;

}
.table-top-bar-item .top-bar-text span{
  font-weight: 500;
  font-size: 15px;
  color: #000;
  font-weight: 600;

  
}
.table-top-bar-item .top-bar-text .Amount{
  font-weight: 500;
  font-size: 15px;
  color: #000;
  font-weight: 600;



  
}
.select-page .setting-icon{
  margin-top: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 0.375rem;

}

.page-icon button{
  color: blue;
  padding: 1px;
  font-weight: 600;
}
.total-count{
  margin-left: 1rem;
  margin-top: 10px !important;
  font-size: 14px;
  font-weight: 400;

}
.table-top-bar{
  background-color: #f9f9fb;
  border: 1px solid #ebeaf2;
  height: 90px;
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1px;
  border-radius: 0.475rem;

}
.graph-card{
  position: relative;
  top: -80px;
}
.date-search-footer{
  border-top: 1px solid #ebeaf2;

}
.date-search-footer .btn{
  border: 1px solid #ebeaf2;
  border-radius: 0.375rem;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 400;
  height: 2.2rem;
} 
.date-search-footer .search-btn{
  background-color: #22b378 !important;
  color: white !important;
}
.date-search-footer .cancel-btn{
  background-color: #f5f5f5;
}
/* .table-top-bar .table-summary{
  colo
} */

.add-form-tabs{
  margin-top: 8px;
  
}

.add-form-tabs .nav-item{
  padding-bottom: 5px;  
  padding-left: 0px !important;

}
.add-form-tabs .nav-item button{
  color: #21263c ;
  border: none;
  font-weight: 400;
  padding-bottom: 5px;
  width: fit-content;
  font-size: 14px;
  /* padding-left: 0px; */


}
.add-form-tabs .nav-item .nav-link{
  padding-left: 0px;
}
.add-form-tabs .nav-item .nav-link.active{
  font-weight: 800;
  border-bottom: 3px solid #408DFB !important;
}
 .tab-icon{
  width: 16px;
  height: 16px;
  border: 1px solid #ebeaf2;
  border-radius: 50%;
  padding: 3px;
  margin-bottom: 11px;
  background-color: #016531;
  color: white;
  

}

.check-green{
  background-color: #016531;

}
.check-red{
  background-color: red;

}


.vendor_details .vendor_details_main{
  width: 100%;
}
.vendor_details .vendor_details_sidebar{
  width: 300px;
  border-right: 1px solid #e4e4e4;
  height: 100vh;
}
.vendor_details .overview_details_sidebar{ 
  border-right: 1px solid #eee;
    background-color: #fbfbfb;
    width: 34%;
    vertical-align: top;
    padding: 10px 20px 70px;
    word-wrap: break-word;
    height: 100vh;
}
.vendor_details .vendor_details_sidebar .all-vendor{
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  height: 60px;
  padding: 16px 10px 16px 16px;
  
}

.overview_details_sidebar .all-vendor{
  border-bottom: 1px solid #e4e4e4;
  height: 50px;
  padding: 15px 0 0;
}
.vendor_details .vendor_details_sidebar .all-vendor-text {
  font-size: 16px;
  color: #444;
  padding-top: 4px;
  font-weight: 500;
}
.vendor_details .vendor_details_sidebar .vendor-item{
  overflow-y: auto;
  height: 100%;
}
.vendor_details .vendor_details_sidebar .vendor-table{
  margin-bottom: 3rem;

}
.overview_details_sidebar .acordian-box {
  font-size: 13px;
  font-weight: 300;
}
.overview_details_sidebar .acordian-box .chakra-accordion__button{
  background-color: transparent;
  box-shadow: none;
  padding: 10px 0 5px;
  height: 50px;
  font-size: 13px;
  font-weight: 300;
}
.vendor_details .acordian-box .chakra-accordion__icon{
  color: #5a5ce2;
  font-size: 18px;
  font-weight: 600;
}
.overview_details_sidebar .acordian-box .text2 {
  /* margin-left: 2.5rem; */
  width: 160px;
}
.vendor_details .vendor_details_sidebar .table-row{
  cursor: pointer;
}

.vendor_details_main .apexcharts-menu{
  width: 120px;
}
.vendor_details .vendor_details_sidebar .table-data{
  border-bottom: 1px solid #e4e4e4;
  font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif;

}
.vendor_details .vendor_details_sidebar .table-data.active{
  box-shadow: inset 0 0 0 9999px #f1f1fa;
}
.vendor_details .vendor_details_main .main_details-header{
  height: 65px;
  width: 100%;
  /* margin-left: 10px; */

}
.vendor_details .vendor_details_main .sub_tab_header{
  margin-top: 8px;
  height: 50px;
}
.vendor_details .vendor_details_main .sub_tab_header .tab-content{
  overflow-y: auto;
  overflow-x: hidden;
  height: 83vh;
}
.vendor_details_main .graph-year-select{
  border: none;
  outline: none;
  box-shadow: none !important;
  width: 130px;
  font-size: 13px !important; 

}
.vendor_details_main .chakra-select__icon-wrapper{
  color: #5a5ce2;
}

.graph-dropdown-select {
  margin: 10px;
  margin-bottom: 5px;
  width: 25vw !important;
  height: 4vh;
}

.vendor_details_main .expense_details{
  width: 100% !important;
  padding: 16px 10px 16px 16px;
}
.vendor_details_main .expense_details_main{
  width: 100% !important;
}
.expense_details .acordian-box .chakra-accordion__button{
  background-color: transparent;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 0;
  height: 45px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
  border-radius: 10px;

  background-color: #f9f9fb;
}
.vendor-search-field{
  outline: none !important;
  margin: 5px;
  border-radius: 0.375rem;
  border: 1px solid #e4e4e4;
  width: 97%; 
  padding: 8px;
  
}
.expense_details .export_vendor{
  background: #fff !important;
  border: none;
  outline: none;
}
.expense_details .acordian-box  .acordian-item{
  border: 1px solid #e4e4e4;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
  background-color: #f9f9fb;
}
.vendor-menu-list{
  min-width: 6rem !important;
  padding: 5px;
}
.vendor-menu-list button{

  font-weight: 600;
  border-radius: 8px;
}
.vendor-menu-list button:hover{
  background-color: #000d55;
  color: white;
}
.credit-table .credit-input{
  width: 120px;
  border: 1px solid #ccc;
  height: 24px;
  margin: 1px;
  outline: none !important;
  box-shadow: none !important;
}
.credit-table-info .input-group{
  width: 200px;
  margin-left: 30px;
}
.credit-table-info .credit-input-text{
  height: 25px;
}
.credit-table .credit-table-info th{
  border: 1px solid #ccc;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}
.credit-table .credit-table-info tbody tr td{
  border: 1px solid #ccc;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}
.credit-table-info .credit-add-btn{
  color: #22b378;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.badge {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #000 !important;
  
}
.add-new-menu-btn span{
  font-size: 14px;
  font-weight: 600;
}
.add-new-menu-btn .new-plus-icon{
  margin: 5px;
}
.add-new-btn .menu-list{
  padding: 10px;
}
.add-new-btn .menu-list .menu-item{
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 600;
}
.add-new-btn .menu-list .menu-item:hover{
  background: #22B37B ;
  color: white !important;
}

.badge .badge-crno{
  color: #324de6;
}
.badge-box{
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: flex-start;
  margin: 3px;
}
.badge-box span{
  font-weight: 600;
}
.credit-close-icon{
  display: flex;
  justify-content: center;
  align-items: center;
 
  height: 20px;
  width:20px;
  margin-top: .5rem;
  margin-left: .1rem;
  cursor: pointer;
}
.credit-ring-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 20px;
  width:20px;
  background-color: #fff;
  margin-top: .5rem;
  margin-left: .5rem;
}
.submitted-badge {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.view-menu-btn{
  right: 8px !important;
  position: relative;
  background-color: #000d55;
  width: 30px;
  border-radius: .375rem;
  height: 30px;
  padding-left: 5px;
}
.view-menu-btn .view-icon-btn{
  font-weight: 600 !important;
  color: #ffff;
}
 .view-menu-list .menu-item:hover{
  background: #b3b3b3 !important;
  color: white !important;
}
.view-menu-list .active-item{
  background: #000d55 !important;
  color: white !important;
}

.expense-table-pagination .page-icon{
  /* border: 1px solid #ccc; */
  margin-right: 10px;
  margin-bottom: 14px;
  margin-left: 0px;
  border-left: 0;
  box-shadow: 0px 0px 1px #ccc;  

  border-bottom-left-radius: 0 !important;  
  border-top-left-radius: 0 !important;  
  height: 35px;

}

.expense-table-pagination .page-icon .chakra-button{
  min-width: 2rem !important;  
  font-size: 14px;
  background: transparent;
  color: rgb(136, 190, 240);
}
.expense-table-pagination .select-page{
  /* border: 1px solid #ccc; */
  border-left: 0 !important;
  background-color: #fff;

  border-bottom-right-radius: 0 !important;  
  border-top-right-radius: 0 !important;  
  box-shadow: 0px 0px 1px #ccc; 
  height: 35px;
  
}
.expense-table-pagination .select-page select{
  border:none;  
  box-shadow: none !important;
  cursor: pointer;
  height: 35px;



}
.tds-po-gen{
  margin-top: 13px;
  right: 1px;
  position: absolute;

}
.total-tds-amount{
  display: flex;
  position: absolute;
  right: 10px;
  font-weight: 600;
  border-top: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;

  padding-top: 5px;
  padding-bottom: 5px;


}
.total-tds-amount .heading{
  width: 120px;
  margin: 0;
}
.total-tds-amount .value{
  margin: 0;
}
.is-disable-delete{
  color: rgb(240, 125, 125) !important;
  cursor: not-allowed !important;
}
.auth-footer .auth-footer-text{
  
  color: #1d1e1f;
  bottom: 0px;
  left: 40%;
  position: absolute;
  font-size: 17px;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;

}

@media (max-width: 992px) {
  .arrow_button {
    display: none;
  }
}