.payment-mode-details{
    position: relative;
    /* box-shadow: 0px 0px 12px #ccc; */    
    background-color: #fff !important;
    width: 840px;
    z-index: 1000;
    /* padding:  0.200000in  0.450000in; */
    margin-bottom: 20px;
    padding:  0.400000in  0.450000in;
    margin-top: 3px;
}
.payment-pdf-info-modal{
    box-shadow: 0px 0px 12px #ccc !important; 

}
.payment-mode-details .payment-pdf-logo{
    max-width: 130px;
    /* background-color: #000000; */
    margin-left: 0px;
    border-radius: 3px;
    /* margin-top: 20px; */
    height: 115px;
}
.payment-pdf-header .header-box{
    width: 350px !important;
    margin-left: 20px;
}
.width_150{
    width: 150px;
}
.width_120{
    width: 120px;
}
.width_100{
    width: 92px;
}
.payment-mode-details .payment-pdf-header .title{
    font-weight: 600;
    font-size: 13pt;
    text-transform: uppercase !important;
}
.payment-template .close-btn{
    position: absolute;
    top: .8rem;
    right: 1rem;
    font-size: 18px;
    background-color: none;
    color: red;
}
.payment-template .pdf-downlode-icon{
    position: absolute;
    top: .8rem;
    right: 3rem;
    font-size: 18px;
    background-color: none;
}
.payment-mode-details .payment-pdf-header .address{
    font-weight: 400;
    font-size: 11pt;
    margin-top: 0px !important;
}

.payment-mode-details .payment-mads{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    text-transform: uppercase;
    padding: 35px 0 50px;
    font-family: Ubuntu;
    font-size: 13pt;
    color: #333333;
}
.payment-mode-details .payment-details{
    width: 70%;
    float: left;
}
.payment-mode-details .payment-details .payment-text-div{
    width: 100%;
    padding: 11px 0px;
    margin-bottom: 18px;
}
.payment-text-div .text1{
    width: 35%;
    float: left;
    color: #777777;
}
.payment-text-div .text2{
    width: 65%;
    /* border-bottom: 1px solid #eee; */
    float: right;
    color: #333333;
    text-transform: capitalize;

}
.payment-mode-details .amount-div{
    text-align: center;
    color: #ffffff;
    float: right;
    background: #78ae54;
    width: 25%;
    padding: 34px 5px;
    font-size: 13pt;
}
.payment-mode-details .amount-div .pcs-total{
    font-size: 16pt !important;
    color: #ffffff;

}
.payment-mode-details .clear{

    clear: both;
}
.payment-mode-details .payment-table-box{
    margin-top: 1rem;
}
.payment-table-box .heading{
    margin-bottom: 18px;
    color: #333333;
    font-size: 12pt;
    font-weight: 600;
    /* margin-top: 10px; */

}
.payment-table-box table{
    width: 100%;
    table-layout: fixed;
    caption-side: bottom;
    bordercollapse: collapse;

}
.payment-table-box table thead{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.payment-table-box table thead tr{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    height: 40px;
}
.payment-table-box table thead tr th{
    padding: 0px 8px 0px 10px;
    word-wrap: break-word;
    font-size: 10pt;
    color: #333333;
    background-color: #eff0f1;
    border-color: inherit;
    border-style: solid;
    border-width: 10;
    text-transform: capitalize;

}
.payment-table-box table tbody tr{
    border-top: 1px solid #ededed;
    border-color: #000 !important;
    border-style: solid;
    border-width: 0;
}
.payment-table-box table tbody tr td{
    padding: 10px 0px 10px 10px;
    word-wrap: break-word;
    font-size: 11pt;
    border-bottom: 1px solid #ece7e7;
    background-color: #ffffff;
    color: #333333;
   
}
.payment-mode-details .paid-to{
    margin-top: 60px;
    width: 100%;
}
.payment-mode-details .paid-to .label{
    font-weight: 600;
    color: #777777;
    margin-top: 0;
    margin-bottom: 1rem;
}
.payment-pdf-header p {
    margin-top: 0px;
}
.payment-mode-details .paid-to .address{
    /* font-size: 16px; */
    margin-top: 0px !important;
    /* margin-bottom: 10px !important; */
}
.payment-mode-details .paid-to .address p{
    margin-top: 0px !important;
}
.payment-mode-details .paid-to .address .company-name{
    font-weight: 600;
    font-size: 13pt;
    margin-bottom: 0px !important;
    text-transform: uppercase !important;
}